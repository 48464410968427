import {Autocomplete} from "@mui/joy";
import React, {useEffect, useState} from "react";
import {useAppSelector} from "../store/hook";
import {TextBase, textStyle} from "./base/TextBase";
import {httpClient} from "../httpClient";

export enum StyleSearchEnum {
    HEADER,
    COLUMN
}

interface Props {

    url: string
    stringSearchName: string; // NOM DE LA DONNE QUI DONNE L'AUTO COMPLETION DANS LA REQUETE
    valueName: string         // NOM DE LA VALEUR DANS LA REQUETE
    title: string
    tooltip?: string
    suffix?: string
    style? : StyleSearchEnum;

}




export function SearchStringData({url,stringSearchName,valueName,title,tooltip,suffix,style} : Props){

    const maxDate = useAppSelector((state) => state.maxDate.value)
    const minDate = useAppSelector((state) => state.minDate.value)

    const [data, setData] = useState<any>([]);
    const [name, setName] = useState<string>("");
    const [value, setValue] = useState<any>()

    const handleChange = (
        event: React.SyntheticEvent | null,
        newValue: any,
    ) => {
        if(newValue) {
            setName(newValue[stringSearchName])
            setValue(newValue[valueName])
        }
    };

    useEffect(() => {

        httpClient.get(url).then((response) => {
            setData(response.data)
        })

    }, [minDate,maxDate,url]);


    useEffect(() => {

        if(Array.isArray(data)){
            data.map((v) => {

                if(v[stringSearchName] === name){

                    setValue(v[valueName])

                }

            })
        }

    }, [name,data]);

    return(
        <div style={styles.container}>

            <div style={style === StyleSearchEnum.HEADER ? styles.header : styles.colum}>
                <TextBase
                    text={title}
                    style={textStyle.soustitle}
                    tooltip={tooltip}/>
                <Autocomplete
                    options={data}
                    getOptionLabel={(option:any) => option[stringSearchName]}
                    onChange={handleChange}
                    size={"sm"}
                    sx={style === StyleSearchEnum.HEADER ? {width: '110px'} : {}  }
                />
            </div>

            <div style={styles.textContainer}>
                <TextBase text={suffix + " " + value} style={textStyle.bold}/>
            </div>

        </div>
    )
}

const styles = {

    container:{
        borderRadius: "20px",
        width: "18vw",
        boxShadow: "0px 1px 15px 0px rgba(0, 0, 0, 0.25)",
        padding: 16,
    },
    header:{
        margin: 5,
        display: "flex",
        gap:5,
        alignItems: "center"
    },
    colum:{
        margin: 10,
    },
    textContainer:{
        margin :10
    }

}
