// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.item{
    display: flex;
    gap: 10px;
    margin-top: 18px;
    padding: 10px;
    border-radius: 5px;
    align-items: center;
}
.item:hover{
    background-color: #F7F7F7;
}
`, "",{"version":3,"sources":["webpack://./src/components/Menu.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,SAAS;IACT,gBAAgB;IAChB,aAAa;IACb,kBAAkB;IAClB,mBAAmB;AACvB;AACA;IACI,yBAAyB;AAC7B","sourcesContent":[".item{\n    display: flex;\n    gap: 10px;\n    margin-top: 18px;\n    padding: 10px;\n    border-radius: 5px;\n    align-items: center;\n}\n.item:hover{\n    background-color: #F7F7F7;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
