import {createSlice, PayloadAction} from "@reduxjs/toolkit";

interface minDateState {
    value: Date
}

const initialState: minDateState = {
    value: new Date("2000-01-01"),
}

export const minDate = createSlice({
    name: "minDate",
    initialState,
    reducers:{
        setMinDate: (state,action: PayloadAction<Date>) =>{
            state.value = action.payload
        }
    }
})

export const {setMinDate} = minDate.actions
export default minDate.reducer
