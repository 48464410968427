import {BarChart, LineChart} from "@mui/x-charts";
import React, {useEffect, useState} from "react";
import {useAppSelector} from "../store/hook";
import {Select} from "@mui/joy";
import Option from "@mui/joy/Option";
import {httpClient} from "../httpClient";

enum chartType{
    LINE = "line",
    BAR = "bar"
}

interface Props {

    url?: string
    data? : any
    title: string
    sectionDateNameValue?: string // Si il y a un sous json style {date: {time: "2020-10-01"}} c'est donc "date"
    valueName: string    // avec l'exemple du dessus c'est time
    stringName?: string // Nom de la valeur exemple le nom des centre ( depuis le json ) si elle est pas renseigner la valeur doit etre une date
    isSum: boolean      // si l'on doit faire +1 pour chaque donnée exemple on doit compte nous même le nombre de patient dans un array
    selectType?: boolean
}

export function StringDataChart({url,sectionDateNameValue,valueName,data, isSum, stringName, title, selectType}:Props){

    const [dataset, setDataset] = useState<{string:string, value:number}[]>([]);
    const [datas, setDatas] = useState<any>();
    const [type, setType] = useState<chartType>(chartType.BAR)

    const maxDate = useAppSelector((state) => state.maxDate.value)
    const minDate = useAppSelector((state) => state.minDate.value)

    useEffect(() => {
        if(url){
            httpClient.get(url).then((response) => {
                setDatas(response.data)
            })
        }else if (data){
            setDatas(data)
        }
    }, [minDate, maxDate,data, url]);

    useEffect(() => {
        let initMap = new Map()

        if(Array.isArray(datas)) {
            datas.map((value) => {

                let includeDate: Date

                if (sectionDateNameValue) {
                    includeDate = new Date(value[sectionDateNameValue][valueName].toString().split("T")[0])
                } else {
                    includeDate = new Date(value[valueName].toString().split("T")[0])
                }

                if (includeDate >= minDate && includeDate <= maxDate) {
                    if(isSum){
                        let mouth = includeDate.toLocaleString('default', {month: 'long'})
                        let year = includeDate.getFullYear()
                        let label = mouth + " " + year

                        if (initMap.get(label) == null) {
                            initMap.set(label, 1)
                        } else {
                            initMap.set(label, initMap.get(label) + 1)
                        }

                    }else if (stringName && !isSum){

                        let label = value[stringName]
                        let valueElement = value[valueName]
                        initMap.set(label, valueElement)

                    }
                }
            })

            let datasets: { string: string, value: number }[] = []
            initMap.forEach((value, key) => {
                datasets.push({string: key, value: value})
            })

            setDataset(datasets)
        }
    }, [datas]);

    const handleChange = (
        event: React.SyntheticEvent | null,
        newValue: chartType | null,
    ) => {
        if(newValue) setType(newValue)
    };

    return(
        <div style={styles.componentContainer}>
            {selectType &&
                <div style={styles.selector}>
                    <Select defaultValue={chartType.BAR} onChange={handleChange}>
                        <Option value={chartType.BAR}>Bar</Option>
                        <Option value={chartType.LINE}>Ligne</Option>
                    </Select>
                </div>
            }
            {dataset.length > 0 ? (
                <>
                    { type === chartType.BAR &&
                        <BarChart
                            xAxis={[{ scaleType: 'band', dataKey: 'string' }]}
                            dataset={dataset}
                            height={300}
                            series={[{ dataKey: 'value', label: title,color: '#2759BA' }]}
                        />
                    }
                    { type === chartType.LINE &&
                        <LineChart
                            xAxis={[{ scaleType: 'point', dataKey: 'string'}]}
                            yAxis={[{min: 0}]}
                            dataset={dataset}
                            height={300}
                            series={[{dataKey: 'value', label: title,color: '#2759BA' }]}
                        />
                    }

                </>
                )
                :
                (
                    <>
                        { type === chartType.BAR &&
                            <BarChart
                                xAxis={[{ scaleType: 'band', dataKey: 'string' }]}
                                dataset={[{string: "mars", value: 0}]}
                                height={300}
                                series={[{ dataKey: 'value', label: 'patient inclus',color: '#2759BA' }]}
                            />
                        }
                        { type === chartType.LINE &&
                            <LineChart
                                xAxis={[{ scaleType: 'point', dataKey: 'string'}]}
                                yAxis={[{min: 0}]}
                                dataset={[{string: "mars", value: 0}]}
                                height={300}
                                series={[{dataKey: 'value', label: 'patient inclus',color: '#2759BA' }]}
                            />
                        }

                    </>
                )
            }
        </div>
    )
}

const styles = {

    componentContainer:{
        borderRadius: "20px",
        width: "96%",
        boxShadow: "0px 1px 15px 0px rgba(0, 0, 0, 0.25)",
        padding: 16
    },
    selector:{
        width: 100
    }

}
