import React, {useEffect, useState} from "react";
import {TextBase, textStyle} from "./base/TextBase";
import {useAppSelector} from "../store/hook";
import {httpClient} from "../httpClient";

export enum MathType {
    AVERAGE,
    DIFFERENCE
}

type Props = {
    url: string,
    firstDataName: string,
    secondDataName?: string;
    title: string
    dateble?: boolean
    dateName?: string
    dataSuffix?:string
    mathType: MathType
}

export function AverageCard({url,firstDataName,secondDataName, dateName ,title, dateble ,dataSuffix ,mathType}:Props){

    const [moyenne, setMoyenne] = useState<number>(0)

    const maxDate = useAppSelector((state) => state.maxDate.value)
    const minDate = useAppSelector((state) => state.minDate.value)


    useEffect(() => {
        httpClient.get(url).then((response) => {

            let length = 0
            let all = 0
            if(Array.isArray(response.data)){
                response.data.forEach((v:any) => {
                    if(dateName && dateble && v[dateName]){
                        let date = new Date(v[dateName])
                        if(date >= minDate && date <= maxDate){
                            length++
                            if(mathType === MathType.DIFFERENCE && secondDataName){
                                all += (v[secondDataName] - v[firstDataName])
                            }
                            else if (mathType === MathType.AVERAGE){
                                all += v[firstDataName]
                            }
                        }
                    } else {
                        length++
                        if(mathType === MathType.DIFFERENCE && secondDataName){
                            all += (v[secondDataName] - v[firstDataName])
                        }
                        else if (mathType === MathType.AVERAGE){
                            all += v[firstDataName]
                        }
                    }
                })
            }
            setMoyenne(all/length)
        })
    }, [minDate,maxDate]);

    return(
        <div style={styles.container}>
            <div style={styles.headContainer}>
                <TextBase text={title} style={textStyle.soustitle}/>

            </div>
            <div style={styles.datacontainer}>
                {isNaN(moyenne) ? (
                    <TextBase
                        text={"Aucune data a ce moment"}
                        style={textStyle.bold}
                    />
                ):(
                    <TextBase
                        text={moyenne.toFixed(2) + (dataSuffix ? dataSuffix : "")}
                        style={textStyle.bold}
                    />
                )
                }

            </div>
        </div>
    )

}

const styles = {
    container:{
        borderRadius: "20px",
        width: "18vw",
        boxShadow: "0px 1px 15px 0px rgba(0, 0, 0, 0.25)"
    },
    datacontainer:{
        padding: 24,
    },
    headContainer:{
        padding: 10,
        borderBottom: "solid rgba(153, 153, 153, 0.50) 1px",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center"
    },
}
