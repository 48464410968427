import {TextBase, textStyle} from "../components/base/TextBase";
import React from "react";

export function Scoring() {


    return(
        <TextBase text="Scoring" style={textStyle.title}/>

    )


}
