import React from 'react';
import ReactDOM from 'react-dom/client';
import "./font.css"
import {
    createBrowserRouter,
    RouterProvider,
} from "react-router-dom";
import {Menu} from "./components/Menu";
import {Overwiew} from "./page/Overwiew";
import {Observance} from "./page/Observance";
import {Patient} from "./page/Patient";
import {Scoring} from "./page/Scoring";
import {Provider} from "react-redux";
import {store} from "./store/store";
import {Centre} from "./page/Centre";

import Keycloak from 'keycloak-js';
import {httpClient} from "./httpClient";

const keycloak = new Keycloak ({
    url: process.env.REACT_APP_KEYCLOAK_URL,
    realm: "ensweet-staging",
    clientId: "ensweet-dashboard",
})

try {
    await keycloak.init({
        onLoad: "login-required",
        checkLoginIframe: true,
        pkceMethod: "S256"
    }).then((auth) => {
        if(!auth){
            window.location.reload()
        }else{
            httpClient.defaults.headers.common['Authorization'] = `Bearer ${keycloak.token}`;
            console.log(`User is ${auth ? 'authenticated' : 'not authenticated'}`);
        }

    });
} catch (error) {
    console.error('Failed to initialize adapter:', error);
}

const router = createBrowserRouter([
    {
        path: "/",
        element: <Menu/>,
        children: [
            {
                path: "",
                element: <Overwiew/>
            },
            {
                path: "patient",
                element: <Patient/>
            },
            {
                path: "observance",
                element: <Observance/>
            },
            {
                path: "scoring",
                element: <Scoring/>
            },
            {
                path: "sceance",
                element: <></>
            },
            {
                path: "centre",
                element: <Centre></Centre>
            },
            {
                path: "satisfaction",
                element: <></>
            },
        ]
    },
]);


const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <Provider store={store}>
      <RouterProvider router={router} />
  </Provider>
);

