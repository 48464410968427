import {TextBase, textStyle} from "../components/base/TextBase";
import {DataCard} from "../components/DataCard";
import React, {useEffect, useState} from "react";
import {EstablismentModel} from "../models/EstablismentModel";
import {useAppSelector} from "../store/hook";
import {StringDataChart} from "../components/StringDataChart";
import {SearchStringData, StyleSearchEnum} from "../components/SearchStringData";
import {httpClient} from "../httpClient";

export function Centre() {

    const maxDate = useAppSelector((state) => state.maxDate.value)
    const minDate = useAppSelector((state) => state.minDate.value)

    const [nbOrg, setNbOrg] = useState<number>(0)
    const [nbPatAvgOrg, setNbPatAvgOrg] = useState<number>(0)
    const [dataCount, setDataCount] = useState<any[]>([])



    useEffect(() => {
        httpClient.get(process.env.REACT_APP_BACK_HOST + "/org/all").then((response) => {

            let data = response.data as EstablismentModel[]
            let nb = 0

            data.map((value) => {

                let createdDate = new Date(value.createdAt.toString().split("T")[0])

                if (createdDate <= maxDate && createdDate >= minDate) {
                    nb++;
                }

            })

            setNbOrg(nb)

        })
        httpClient.get(process.env.REACT_APP_BACK_HOST + "/org/countPatient/" + minDate + "/" + maxDate).then((response) => {

            let data = response.data
            let nb = 0

            if(Array.isArray(data)){
                data.map((value) => {
                    nb = nb + value["patienNumber"];
                })
            }



            setNbPatAvgOrg(nb/data.length)
            setDataCount(data)

        })
    }, [minDate, maxDate]);


    return (
        <>
            <TextBase text={"Centre"} style={textStyle.title}/>

            <div style={styles.containerFlex}>
                <DataCard name={"Nombre de centre"} data={nbOrg} tooltip={"Centre crée entre deux date"}></DataCard>
                <SearchStringData
                    url={process.env.REACT_APP_BACK_HOST +"/org/getDayTRByOrgPat/" + minDate + "/" + maxDate + "/"}
                    stringSearchName={"name"}
                    valueName={"day"}
                    title={"Jour de TR total"}
                    tooltip={"Nombre de jour total de télèréadaptation d'un centre"}
                    suffix={"Nombre de jour :"}
                    style={StyleSearchEnum.HEADER}
                />
                <DataCard name={"Patient moyen par centre"} data={nbPatAvgOrg} tooltip={"Nombre moyen de patient par centre"}></DataCard>
            </div>

            <div style={styles.containerFlex}>
                <StringDataChart
                    data={dataCount}
                    title={"Patient crée par centre"}
                    valueName={"patienNumber"}
                    stringName={"name"}
                    isSum={false}
                />
            </div>

        </>
    )

}

const styles = {
    containerFlex: {
        display: "flex",
        justifyContent: "space-between",
        margin: 22
    }
}
