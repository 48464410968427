import {Tooltip} from "@mui/joy";

type Props = {
    text: String | number
    style: any
    tooltip?: string
}

export function TextBase({text, style,tooltip}:Props){

    return(
        <Tooltip title={tooltip} placement="top">
            <p style={style}>{text}</p>
        </Tooltip>
    )

}

export const textStyle = {
        title:{
            fontFamily: "Montserrat",
            fontSize: "44px",
            fontStyle: "bold",
            fontWeight: "600",
            margin:0,
            color: "#1F1F1F"
        },
        soustitle:{
            fontFamily: "Montserrat",
            fontSize: "16px",
            fontStyle: "normal",
            margin:0,
            color: "#999999"
        },
        bold:{
            fontFamily: "Montserrat",
            fontSize: "16px",
            fontStyle: "normal",
            fontWeight: "600",
            margin:0,
            color: "#1F1F1F"
        },
        normal:{
            fontFamily: "Montserrat",
            fontSize: "16px",
            fontStyle: "normal",
            margin:0,
            color: "#1F1F1F"
        }

}
