import {createSlice, PayloadAction} from "@reduxjs/toolkit";

interface maxDateState {
    value: Date
}

const initialState: maxDateState = {
    value: new Date(),
}

export const maxDate = createSlice({
    name: "maxDate",
    initialState,
    reducers:{
        setMaxDate: (state,action: PayloadAction<Date>) =>{
            state.value = action.payload
        }
    }
})

export const {setMaxDate} = maxDate.actions
export default maxDate.reducer

