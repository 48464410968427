import { configureStore } from '@reduxjs/toolkit'
import dateSelectionMin from "./dateSelectionMin";
import dateSelectionMax from "./dateSelectionMax";

export const store = configureStore({
    reducer: {
        minDate: dateSelectionMin,
        maxDate: dateSelectionMax
    },
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch


