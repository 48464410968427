import {CircularProgress, Tooltip} from "@mui/joy";
import React, {useEffect, useState} from "react";
import { AxiosResponse } from "axios";
import {useAppSelector} from "../store/hook";
import {httpClient} from "../httpClient";

export enum CircularVariant {

    CIRCLE = "CIRCLE",
    COMPARE = "COMPARE"

}


interface Props{
    url?: string // URL API SI ON DOIT UTILISER UNE API
    data?: any // DATA SI PAS DE APPEL API NECESSAIRE
    varian?: CircularVariant  // LES VARIANT (SEULEMENT LE CERCLE OU LE MAX / SUM AVEC UN CERCLE)
    isPoucentage? : boolean // SI LA DATA TRAITER EST DEJA UN POUCENTAGE (ex : completude)
    valueName: string // NOM DU CHAMP A TRAITER
    sumValue: boolean // FAUT T'IL FAIRE UNE SOMME DE TOUTE LES VALEUR
    condition?: any // LA SOMME DES VALEUR DOIT T'ELLE AVOIR UNE CONDITION SI OUI LA QUEL (ex: true, "SMOKE", ..)
    datable?: boolean // ESQUE LA DATA PERMET DE POUVOIR UTILISER UN SYSTEME DE CHANGEMENT DE DATE
    dateName?: string // LE NOM DU CHAMP DE LA DATE
    title: string // LE TITRE DE LA CARD
    tooltip?: string
}


export function CircularDataCard({url,valueName,sumValue, condition, title , dateName , datable, varian, isPoucentage,data ,tooltip} : Props){

    const [poucent, setPoucent] = useState<number>(0)
    const [max, setMax] = useState<number>(0)
    const [refresh , setrefresh] = useState<boolean>(true)
    const [sum , setSum] = useState<number>(0)
    const [datas , setDatas] = useState<AxiosResponse<any,any>>()

    const maxDate = useAppSelector((state) => state.maxDate.value)
    const minDate = useAppSelector((state) => state.minDate.value)

    function refreshData() {
        if (datas) {
            if (!varian) {
                varian = CircularVariant.CIRCLE
            }

            let sum = 0
            let nbMax = 0

            if (Array.isArray(datas)) {

                datas.forEach((value) => {
                    // Gestion Date
                    if (datable && dateName && value[dateName]) {

                        let date = new Date(value[dateName].split("T")[0])

                        if (date >= minDate && date <= maxDate) {
                            nbMax++
                            if (sumValue) {
                                sum += value[valueName]
                            } else {
                                if (condition === value[valueName]) {
                                    sum++
                                }
                            }

                        }
                    } else {
                        nbMax = datas.length
                    }

                    return value;
                })

                setSum(sum)

                // if the data is alredy a %
                if (isPoucentage) {
                    if (datable) {
                        sum = sum / nbMax
                    } else {
                        sum = sum / datas.length
                    }
                } else {
                    //the % if is datable or not
                    if (datable) {
                        sum = sum * 100 / nbMax
                    } else {
                        sum = sum * 100 / datas.length
                    }
                }

            }
            setPoucent(parseFloat(sum.toFixed(1)))
            setMax(nbMax)
            setrefresh(false)
        }
    }

    useEffect(() => {
        setrefresh(true)
        if(url){
            httpClient.get(url).then((response) => {
                setDatas(response.data)
            })
        }else{
            setDatas(data)
        }
    }, [minDate,maxDate,data,url]);

    useEffect(() => {
        if(datas) {
            setMax(0)
            setSum(0)
            refreshData()
        }
        //eslint-disable-next-line
    }, [datas]);

    return(
        <div style={styles.container}>
                    <div>
                        <div style={styles.headContainer}>
                            <Tooltip title={tooltip}>
                                <p style={styles.title}>{title}</p>
                            </Tooltip>
                        </div>


                            {varian === CircularVariant.COMPARE ? (

                                <div style={styles.compareContainer}>
                                    {condition ?
                                        (
                                            <p style={styles.data}>{sum > 0 ? sum : "Il y a aucune donnée"}</p>
                                        ):(
                                            <p style={styles.data}>{max > 0 ? max : "Il y a aucune donnée"}</p>
                                        )
                                    }
                                    <CircularProgress
                                        determinate={!refresh}
                                        value={poucent > 100 ? 100 : poucent}
                                        sx={{ '--CircularProgress-size': '80px' }}>
                                        {poucent ? poucent + "%" : "NO DATA"}
                                    </CircularProgress>
                                </div>


                            ):(
                                <div style={styles.circleContainer}>
                                    <CircularProgress
                                        determinate={!refresh}
                                        value={poucent > 100 ? 100 : poucent}
                                        sx={{ '--CircularProgress-size': '100px' }}>
                                        {poucent ? poucent + "%" : "NO DATA"}
                                    </CircularProgress>
                                </div>
                            )}

                    </div>
        </div>
    )

}

const styles = {
    container:{
        borderRadius: "20px",
        width: "21.5vw",
        boxShadow: "0px 1px 15px 0px rgba(0, 0, 0, 0.25)",
    },
    compareContainer:{
        padding: 15,
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
    },
    headContainer:{
        padding: 10,
        borderBottom: "solid rgba(153, 153, 153, 0.50) 1px",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center"
    },
    title:{
        fontFamily: "Montserrat",
        fontSize: "16px",
        margin: "0",
        width: "fit-content",
        fontStyle: "normal",
        color: "#999999"
    },
    data:{
        fontFamily: "Montserrat",
        fontSize: "16px",
        fontStyle: "normal",
        fontWeight: "600",
        margin:0,
        color: "#1F1F1F"
    },
    circleContainer:{
        padding: 15,
        display: "flex",
        justifyContent: "center",
    },

}
