import {TextBase, textStyle} from "../components/base/TextBase";
import React from "react";
import {CircularDataCard} from "../components/CircularDataCard";

export function Observance() {


    return(
        <div>
            <TextBase text="Observance" style={textStyle.title}/>
            <CircularDataCard
                url={process.env.REACT_APP_BACK_HOST+"/program/all"}
                valueName={"status"}
                sumValue={false}
                condition={3}
                datable={true}
                dateName={"date"}
                title={"Observance Moyenne"}
            />
        </div>
    )


}
