import {Tooltip} from "@mui/joy";

interface Props{
    name: string
    data: number | string
    tooltip?: string
}

export function DataCard({data,name,tooltip} : Props) {

    return(
            <div style={styles.container}>
                <div style={styles.headContainer}>
                    <Tooltip title={tooltip} placement="top">
                        <p style={styles.title}>{name}</p>
                    </Tooltip>
                    <div style={styles.containIcon}></div>
                </div>
                <p style={styles.data}>{data}</p>
            </div>

    )

}

const styles = {
    container:{
        padding: 20 ,
        borderRadius: "20px",
        width: "15vw",
        boxShadow: "0px 1px 15px 0px rgba(0, 0, 0, 0.25)"
    },
    title:{
        fontFamily: "Montserrat",
        fontSize: "16px",
        fontStyle: "normal",
        margin:0,
        color: "#999999"
    },
    data:{
        fontFamily: "Montserrat",
        fontSize: "16px",
        fontStyle: "normal",
        fontWeight: "600",
        margin:0,
        color: "#1F1F1F"
    },
    headContainer:{
        display: "flex",
        gap: "10px",
        alignItems: "center",
        justifyContent: "space-between",
        marginBottom: "10px"
    },
    containIcon: {
        backgroundColor: "#033AA1",
        height: "30px",
        width: "30px",
        borderRadius: 8,
    }
}

