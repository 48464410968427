import {TextBase, textStyle} from "../components/base/TextBase";
import React, {useEffect, useState} from "react";
import {DataCard} from "../components/DataCard";
import {UserIncludedModel, UserInvocingModel, UserModel} from "../models/user.model";
import {CircularDataCard} from "../components/CircularDataCard";
import {AverageCard, MathType} from "../components/AverageCard";
import {MedicalInfoModel} from "../models/MedicalInfoModel";
import {useAppSelector} from "../store/hook";
import {StringDataChart} from "../components/StringDataChart";
import {SearchStringData} from "../components/SearchStringData";
import {httpClient} from "../httpClient";

export function Patient() {

    const [ageAvg, setAgeAvg] = useState<number>(0);
    const [nbUser, setnbUser] = useState<number>(0);
    const [nbUserInvocing, setnbUserInvocing] = useState<number>(0);
    const [nbUserIncluded, setnbUserIncluded] = useState<number>(0);
    const [patientRisk, setPatienRisk] = useState<number>(0);
    const [patientWithouRisk, setPatienWitouRisk] = useState<number>(0);

    const [includedData, setIncludedData] = useState<any>();


    const maxDate = useAppSelector((state) => state.maxDate.value)
    const minDate = useAppSelector((state) => state.minDate.value)

    useEffect(() => {

        let user:UserModel[]
        let userInvocing:UserInvocingModel[]

        httpClient.get(process.env.REACT_APP_BACK_HOST+"/user/allInvocing").then((response) => {
            userInvocing = response.data as UserInvocingModel[]

            let nbInvocing = 0

            userInvocing.map((u) => {
                let invocingAt = new Date(u.invocing.time.toString().split("T")[0])

                if(invocingAt >= minDate && invocingAt <= maxDate){
                    nbInvocing++
                }

            })

            setnbUserInvocing(nbInvocing);
        })

        httpClient.get(process.env.REACT_APP_BACK_HOST+"/user/all").then((response) => {
            user = response.data as UserModel[]

            let avg = 0
            let actifs = 0
            let nbPatient = 0

            user.map(value => {
                let useCreatedAt = new Date(value.createdAt.toString().split("T")[0])
                if( useCreatedAt <= maxDate && useCreatedAt >= minDate){
                    avg += value.age
                    if(value.isactif){
                        actifs++
                    }
                    nbPatient++
                }
            })

            setAgeAvg(avg/response.data.length)
            setnbUser(nbPatient)

            httpClient.get(process.env.REACT_APP_BACK_HOST+"/user/info/allByUuid").then((response) => {
                const userinfo = response.data as MedicalInfoModel[]

                let patientWithRisque = 0
                let patientWithoutRisque = 0


                userinfo.map((value) => {
                    const userOfInfo = user.find((u) => u.uuid === value.patientuuid)
                    if(userOfInfo){
                        let userCreatedAt = new Date(userOfInfo.createdAt.toString().split("T")[0])
                        if(userCreatedAt <= maxDate && userCreatedAt >= minDate){
                            if(value.highBloodPressure === "true" || value.diabetes === "true" || value.hypercholesterolaemia === "true"  || value.smoking === "yes" || value.smoking === "weaning"){
                                patientWithRisque++;
                            }else{
                                patientWithoutRisque++
                            }
                        }
                    }


                })
                setPatienRisk(patientWithRisque)
                setPatienWitouRisk(patientWithoutRisque)
            })

            httpClient.get(process.env.REACT_APP_BACK_HOST+"/user/allIncluded/").then((response) => {
                const userincludeds = response.data as UserIncludedModel[]
                let includedNb = 0
                userincludeds.map((value) => {
                    let userincludedDate = new Date(value.included.startDate.toString().split("T")[0])
                    if(userincludedDate >= minDate && userincludedDate <= maxDate){
                        includedNb++
                    }
                })
                setnbUserIncluded(includedNb)
                setIncludedData(response.data)


            })
        })

    }, [minDate, maxDate]);

    return(
        <div>
            <TextBase text="Patient" style={textStyle.title}/>

            <div>
                <div style={styles.containerFlex}>
                    <DataCard data={nbUser} name={"Nombre de patient"} tooltip={"Patient crée entre deux date"}/>
                    <DataCard data={nbUserInvocing} name={"Nombre de patient facturable"} tooltip={"Patient ou sa premiere session effectuer est entre deux date"}/>
                    <DataCard data={nbUserIncluded} name={"Patient inclus"} tooltip={"Patient avec un programme ( ou la date de début et passé ou actuel )"}/>
                </div>

                <div style={styles.containerFlex}>
                    <DataCard data={patientWithouRisk} name={"Nombre de patient sans risque"} tooltip={"seul les patient avec une fiche patient est pris en compte"}/>
                    <DataCard data={ageAvg.toFixed(2)} name={"Age moyen"}/>

                    {/*TODO CAR JE SAIS PAS ENCORE QUELS DATA USE*/}
                    {/*{patientInfo &&*/}
                    {/*    <DataTypeCard*/}
                    {/*        type={test}*/}
                    {/*        data={patientInfo}*/}
                    {/*        title={"Patien avec un type de facteur"}*/}
                    {/*        suffix={" Patient a risque"}*/}
                    {/*        dateable={true}*/}
                    {/*        dateNameValue={""}*/}
                    {/*    />*/}
                    {/*}*/}

                    <DataCard data={patientRisk} name={"Nombre de patient a risque"} tooltip={"seul les patient avec une fiche patient est pris en compte"}/>
                </div>
                <div style={styles.containerFlex}>
                    <StringDataChart
                        data={includedData}
                        title={"Patients inclus"}
                        valueName={"startDate"}
                        isSum={true}
                        sectionDateNameValue={"included"}
                        selectType={true}
                    />
                </div>
                <div style={styles.containerFlex}>
                    <CircularDataCard
                        url={process.env.REACT_APP_BACK_HOST+"/program/all/"}
                        valueName={"status"}
                        sumValue={false}
                        condition={3}
                        datable={true}
                        dateName={"date"}
                        title={"Observance Moyenne"}
                    />
                    <SearchStringData
                        url={process.env.REACT_APP_BACK_HOST+"/org/getDayTRByAllPat/"+minDate+"/"+maxDate+"/"}
                        stringSearchName={"uuid"}
                        valueName={"day"}
                        title={"Jour de TR total"}
                        suffix={"Jour de tr :"}
                    />
                    <AverageCard
                        url={process.env.REACT_APP_BACK_HOST+"/training/trainCount/"}
                        firstDataName={"count"}
                        title={"Seance moyenne par patient"}
                        dataSuffix={" / patient"}
                        mathType={MathType.AVERAGE}
                    />
                </div>

            </div>

        </div>

    )

}

const styles = {
    containerFlex: {
        display: "flex",
        justifyContent: "space-between",
        margin: 22
    }
}

