import {DataCard} from "../components/DataCard";
import {LinearDataCard} from "../components/LinearDataCard";
import {CircularDataCard, CircularVariant} from "../components/CircularDataCard";
import React, {useEffect, useState} from "react";
import {UserModel} from "../models/user.model";
import {AverageCard, MathType} from "../components/AverageCard";
import {TextBase, textStyle} from "../components/base/TextBase";
import {httpClient} from "../httpClient";
import {useAppSelector} from "../store/hook";

export function Overwiew(){

    const [userData, setUserData] = useState<UserModel[]>();

    const maxDate = useAppSelector((state) => state.maxDate.value)
    const minDate = useAppSelector((state) => state.minDate.value)

    const [ageAvg, setAgeAvg] = useState<number>(0);
    const [nbUser, setnbUser] = useState<number>(0);
    const [actif, setActif] = useState<number>(0);

    useEffect(() => {
        httpClient.get(process.env.REACT_APP_BACK_HOST+"/user/all").then((response) => {
            const user = response.data as UserModel[]

            let avg = 0
            let actifs = 0
            let nbPatient = 0
            setUserData(user)

            user.map(value => {

                let useCreatedAt = new Date(value.createdAt.toString().split("T")[0])
                if( useCreatedAt <= maxDate && useCreatedAt >= minDate){
                    avg += value.age
                    if(value.isactif){
                        actifs++
                    }
                    nbPatient++
                }
            })

            setAgeAvg(avg/response.data.length)
            setActif(actifs)
            setnbUser(nbPatient)
        })
    }, [maxDate,minDate]);


    return(
        <div>
            <TextBase text="BIENVENUE 👋" style={textStyle.title}/>
            <div style={styles.headContainer}>
                <DataCard data={nbUser} name={"Patient crée"} tooltip={"Patient crée dans l'interval de temps"}/>
                <DataCard data={ageAvg.toFixed(2)} name={"Age moyen"} tooltip={"Age moyen des patient crée"}/>
                <DataCard data={actif} name={"Patient actif"} tooltip={"Patient actif la date pris en compte et la date de création du patient"}/>
            </div>
            <div style={styles.headContainer}>
                <LinearDataCard
                    url={process.env.REACT_APP_BACK_HOST+"/user/all"}
                    valueName={"sex"}
                    firstCondition={"M"}
                    secondCondition={"F"}
                    firstColor={"blue"}
                    secondColor={"pink"}
                    titre={"Homme / Femme"}
                    datable={true}
                    dateNameValue={"createdAt"}
                />
                <CircularDataCard
                    url={process.env.REACT_APP_BACK_HOST+"/training/completude"}
                    valueName={"completude"}
                    sumValue={true}
                    isPoucentage={true}
                    datable={true}
                    dateName={"startDate"}
                    title={"Completude Moyenne"}
                    tooltip={"Complétude sur toute les seances réalisés"}
                />
                <AverageCard
                    url={process.env.REACT_APP_BACK_HOST+"/user/test/first&last"}
                    firstDataName={"first"}
                    secondDataName={"last"}
                    dateName={"lastdate"}
                    title={"Evolution puissance max"}
                    dataSuffix={"w"}
                    dateble={true}
                    mathType={MathType.DIFFERENCE}
                />
            </div>
            <div style={styles.headContainer}>
                <CircularDataCard
                    data={userData}
                    valueName={"art51"}
                    varian={CircularVariant.COMPARE}
                    datable={true}
                    dateName={"createdAt"}
                    sumValue={true}
                    condition={true}
                    title={"Patient Article51"}
                />
            </div>
        </div>

    );
}

const styles = {
    headContainer: {
        display: "flex",
        justifyContent: "space-between",
        margin: 22
    }
}
